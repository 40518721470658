<template>
  <div class="custom-multiselect-dropdown">
    <b-dropdown
      id="dropdown-multiselect"
      :text="dropdownText"
      variant="white"
      menu-class="custom-dropdown-menu"
      toggle-class="custom-dropdown-toggle"
      ref="dropdown"
    >
      <b-dropdown-text text-class="custom-dropdown-text" @click="clearAllSelections">
        Clear All
      </b-dropdown-text>

      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-text
        v-for="option in selectableOptions"
        :key="option.value"
        @click.prevent="toggleSelection(option)"
        text-class="custom-dropdown-text"
      >
        <b-form-checkbox :checked="option.selected" @change="toggleSelection(option)">
          {{ option.label }}
        </b-form-checkbox>
      </b-dropdown-text>
    </b-dropdown>
  </div>
</template>

<script lang="ts">
import { getTextWidth } from '@/utilities/text.utils';
import { BDropdown } from 'bootstrap-vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

type Option = {
  label: string;
  value: string | number;
}

type SelectableOption = Option & {
  selected: boolean;
}

@Component
export default class MultiSelectDropdown extends Vue {
  @Prop({ required: true }) options!: Array<Option>;
  @Prop({ required: true }) entityName!: string;
  @Prop({ default: () => [] }) value!: Array<string | number>;

  selectableOptions: Array<SelectableOption> = [];

  @Watch('options', { immediate: true, deep: true })
  onOptionsChange() {
    this.selectableOptions = this.options.map(option => ({
      ...option,
      selected: this.value.includes(option.value),
    }));
    this.adjustDropdownWidth();
  }

  mounted() {
    this.adjustDropdownWidth();
  }

  get selected() {
    return this.selectableOptions.filter(o => o.selected);
  }

  get dropdownText(): string {
    if (this.selected.length === 0) {
      return `All ${this.entityName}s`;
    } else if (this.selected.length === 1) {
      return this.selected[0].label;
    } else {
      return `${this.selected.length} ${this.entityName}s`;
    }
  }

  toggleSelection(option: SelectableOption) {
    option.selected = !option.selected;
    this.$emit('change', this.selected.map(o => o.value));
  }

  clearAllSelections() {
    this.selectableOptions.forEach(option => (option.selected = false));
    this.$emit('clear');
    (this.$refs.dropdown as BDropdown).hide(true);
  }

  adjustDropdownWidth() {
    const dropdown = (this.$refs.dropdown as BDropdown).$el as HTMLElement;
    if (!dropdown) return;

    const fontStyle = getComputedStyle(this.$el).font;
    let maxWidth = 0;

    this.selectableOptions.forEach(option => {
      const width = getTextWidth(option.label, fontStyle);
      maxWidth = Math.max(maxWidth, width);
    });

    maxWidth = Math.min(maxWidth + 70, 400);
    dropdown.style.width = `${maxWidth}px`;
  }
}
</script>

<style>
.custom-multiselect-dropdown #dropdown-multiselect {
  min-width: 150px;
  max-width: 400px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.custom-dropdown-menu {
  width: 100%;
  max-height: 400px;
  overflow: auto;
}

.custom-dropdown-text {
  cursor: default;
  font-weight: 400 !important;
}

.custom-dropdown-text:hover {
  background-color: #005fd1;
  color: white;
}

.custom-dropdown-toggle {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
